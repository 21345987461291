import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldPhoneNumberInput,
  H4,
  H5,
  FieldHomeLocation,
} from '../../../components';

import css from './SignupForm.module.css';

const SignupFormComponent = props => {
  const { onSubmit, isArtistSignup, authError, ...rest } = props;

  const handleSubmit = values => {
    const { location, address: formAddress, city, ...rest } = values;

    const address = {
      ...location,
      selectedPlace: {
        ...location?.selectedPlace,
        city: city,
        line1: formAddress?.selectedPlace?.line1 || formAddress?.search,
      },
    };
    onSubmit({ ...rest, address, isArtist: isArtistSignup });
  };

  return (
    <FinalForm
      {...rest}
      onSubmit={handleSubmit}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          form,
        } = fieldRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <H4 as="h2" className={css.signupTitle}>
                <FormattedMessage
                  id={
                    isArtistSignup ? 'TopbarDesktop.signupArtist' : 'TopbarDesktop.signupCustomer'
                  }
                />
              </H4>
              {isArtistSignup && (
                <FieldTextInput
                  className={css.artistNameRoot}
                  type="text"
                  id={formId ? `${formId}.artistName` : 'artistName'}
                  name="artistName"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'General.artistName',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'General.artistNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'General.artistNameRequired',
                    })
                  )}
                />
              )}
              {isArtistSignup && (
                <H5 as="h3" className={css.contactInfo}>
                  <FormattedMessage id="SignupForm.contactFieldsTitle" />
                </H5>
              )}
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />

              <div className={css.name}>
                {isArtistSignup && (
                  <label htmlFor={formId ? `${formId}.firstName` : 'firstName'}>
                    <FormattedMessage id="General.artistLeaderFirstName" />
                  </label>
                )}
                <div className={css.nameFieldsWrapper}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.firstName` : 'firstName'}
                    name="firstName"
                    autoComplete="given-name"
                    label={
                      !isArtistSignup
                        ? intl.formatMessage({
                            id: 'SignupForm.firstNameLabel',
                          })
                        : null
                    }
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.firstNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: isArtistSignup
                          ? 'General.leaderNameRequired'
                          : 'SignupForm.firstNameRequired',
                      })
                    )}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lastName` : 'lastName'}
                    name="lastName"
                    autoComplete="family-name"
                    label={
                      !isArtistSignup
                        ? intl.formatMessage({
                            id: 'SignupForm.lastNameLabel',
                          })
                        : null
                    }
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.lastNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: isArtistSignup
                          ? 'General.leaderLastNameRequired'
                          : 'SignupForm.lastNameRequired',
                      })
                    )}
                  />
                </div>
              </div>
              {isArtistSignup ? (
                <FieldHomeLocation
                  intl={intl}
                  formId={formId}
                  fieldClassName={css.field}
                  formRef={form}
                />
              ) : null}

              <FieldPhoneNumberInput
                className={css.phoneNumberRoot}
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                autoComplete="phone-number"
                label={intl.formatMessage({
                  id: 'SignupForm.phoneNumberLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.phoneNumberPlaceholder',
                })}
                validate={
                  isArtistSignup
                    ? validators.required(
                        intl.formatMessage({
                          id: 'SignupForm.phoneNumberRequired',
                        })
                      )
                    : undefined
                }
              />

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}

              {authError}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
